<template>
  <div>
    <navBar :title="title"></navBar>

    <div class="bill-form" v-if="status">
      <ul class="form">
        <li>
          <span>*企业名称:</span>
          <input placeholder="请输入企业名称" type="text" v-model="billInfo.name" />
        </li>
        <li>
          <span>*信用代码:</span>
          <input
            @input="inputs(billInfo.creditCode)"
            placeholder="请输入信用代码"
            type="text"
            v-model="billInfo.creditCode"
          />
        </li>
        <li>
          <span>注册地址:</span>
          <input placeholder="请输入注册地址" type="text" v-model="billInfo.registerAddress" />
        </li>
        <li>
          <span>注册电话:</span>
          <input placeholder="请输入注册电话" type="text" v-model="billInfo.phone" />
        </li>
        <li>
          <span>开户银行:</span>
          <input placeholder="请输入开户银行" type="text" v-model="billInfo.accountOpening" />
        </li>
        <li>
          <span>银行账号:</span>
          <input
            @input="bankAccountInput(billInfo.bankAccount)"
            placeholder="请输入银行账号"
            type="text"
            v-model="billInfo.bankAccount"
          />
        </li>
      </ul>
      <div class="confirm">
        <van-button @click="billSubmit" type="info">确认添加</van-button>
      </div>
    </div>

    <div class="mail-form" v-else>
      <ul class="form">
        <li>
          <span>店名:</span>
          <input placeholder="请输入店名" type="text" v-model="mailInfo.companyName" />
        </li>
        <li>
          <span>*收件地址:</span>
          <input placeholder="请输入收件地址" type="text" v-model="mailInfo.address" />
        </li>
        <li>
          <span>*收件人:</span>
          <input placeholder="请输入收件人" type="text" v-model="mailInfo.name" />
        </li>
        <li>
          <span>*收件电话:</span>
          <input placeholder="请输入收件电话" type="text" v-model="mailInfo.phone" />
        </li>
      </ul>
      <div class="confirm">
        <van-button @click="mailSubmit" type="info">确认添加</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '../navBar'
import { Toast } from 'vant';
export default {
  name: '',
  components: {
    navBar
  },
  data () {
    return {
      title: {
        name: '',
        status: true
      },
      billInfo: {
        id: '',
        accountOpening: '',
        bankAccount: '',
        phone: '',
        creditCode: '',
        name: '',
        registerAddress: '',
        enterpriseUser: {
          id: ''
        }
      },
      mailInfo: {
        id: '',
        companyName: '',
        address: '',
        name: '',
        phone: '',
        enterpriseUser: {
          id: ''
        }
      },
      status: null,//判断出现发票或邮寄页面
    }
  },
  created () {
    this.status = this.$route.params.status;
    console.log('this.$route.params', this.$route.params);

    if (this.status) {
      this.title.name = "添加发票信息"
    } else {
      this.title.name = "添加邮寄信息"
    }
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 格式化 信用代码
    inputs (str) {
      this.billInfo.creditCode = str.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ').toUpperCase();
    },
    // 格式化银行卡号
    bankAccountInput (str) {
      this.billInfo.bankAccount = str.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ');
    },
    // 添加发票信息
    billSubmit () {
      if (this.billInfo.name !== '' && this.billInfo.creditCode !== '') {
        this.billInfo.enterpriseUser.id = this.$store.state.userId;
        this.$request.post(this.$api.addInfo, this.billInfo).then(res => {
          console.log('iyres', res);
          Toast.success('添加成功')
          setTimeout(() => {
            this.$router.go(-1)
            this.$forceUpdate()
          }, 1000)
        })
      } else {
        Toast.fail('请输入必填项');
      }
    },
    // 添加邮寄信息
    mailSubmit () {
      if (this.mailInfo.address != '' && this.mailInfo.name != '' && this.mailInfo.phone != '') {
        let reg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
        let status = reg.test(this.mailInfo.phone)
        if (status) {
          this.mailInfo.enterpriseUser.id = this.$store.state.userId;
          this.$request.post(this.$api.addAddress, this.mailInfo).then(res => {
            Toast.success('添加成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        }else{
          Toast.fail('手机号错误')
        }
      }else{
        Toast.fail('请输入必填项')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  li {
    border-bottom: 1px solid #ccc;
    height: 36px;
    display: flex;
    align-items: center;

    padding-left: 20px;

    &:nth-child(1) {
      padding-left: 13px;
    }
    &:nth-child(2) {
      padding-left: 13px;
    }
    font-size: 14px;
    input {
      border: none;
      width: 70%;
      margin-left: 10px;
    }
  }
}

.mail-form {
  .form {
    li {
      border-bottom: 1px solid #ccc;
      height: 36px;
      display: flex;
      align-items: center;

      padding-left: 20px;

      span {
        display: inline-block;
        text-align: right;
        width: 20%;
      }
      input {
        width: 70%;
        margin-left: 10px;
      }
    }
  }
}

.confirm {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
